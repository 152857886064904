.SubmitButton {
  width: 100px;
  background-color: rgb(0, 213, 255) !important;
  margin-top: 10px;
}

.FormFieldContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.FormField {
  width: 100%;
}

.FormBuilderContainer {
  width: 90vw;
  height: 80vh;
  margin: auto;
}

.FormBuilderContainer::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.FormBuilderContainer::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
.FormBuilderContainer::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #00aeff, #a68eff);
  border-radius: 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 3px 13px 1px;
}

@media screen and (max-width: 1024px) {
  .FormBuilderContainer {
    width: 85vw;
    height: 80vh;
  }
}

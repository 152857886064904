.InitialForm {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.InitialFormScreen {
  width: 60vw;
  margin: auto;
}

.SubmitButton {
  width: 100px;
  margin-top: 10px;
}

.FormFieldContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.FormField {
  width: 100%;
}

@media screen and (max-width: 720px) {
  .InitialFormScreen {
    width: 85vw;
  }
}

.previewContainer {
  width: 90vh;
  /* height: 80vh; */
  margin: auto;
  padding: 1rem;
  padding-inline: 2rem;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(185, 183, 183);
  border-radius: 5px;
  /* background-color: rgb(255, 255, 255); */
  box-shadow: 0 4px 8px 0 rgba(210, 210, 210, 0.2),
    0 6px 20px 0 rgba(202, 202, 202, 0.19);
  /* overflow-y: scroll; */
}

.previewLabel {
  /* margin: 0px; */
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  /* color: black; */
}
.previewRequire {
  content: '*';
  padding: 5px;
  color: red;
  font-weight: bold;
}
.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 20px;
  padding-bottom: 30px;
  background-color: white;
}

.FomBuilderContainer {
  border-radius: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  /* position: fixed; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.FormCanvas {
  padding: 20px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  width: 80%;
  height: calc(100% - 40px);
  overflow: scroll;
}

.FormToolsContainer {
  padding: 20px;
  width: 20%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
}

.FormTool {
  display: flex;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px;
  align-items: center;
}

.FormTool:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .FomBuilderContainer {
    flex-direction: column;
  }

  .FormCanvas {
    width: 100%;
    height: calc(80% - 40px);
  }

  .FormToolsContainer {
    width: 100%;
  }
}

:root {
  --primary-color: #2ba9bf;

  --dark-color: #2f2d2d;
  --midnight-color: #262430;
  --light-color: #fafafa;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: background-color 1s;
}

#light {
  background-color: var(--light-color);
}

#dark {
  background-color: var(--dark-color);
  color: whitesmoke;
}

#midnight {
  background-color: var(--midnight-color);
  color: whitesmoke;
}

a {
  color: #2e3747;
}

a.selected > div {
  background-color: var(--primary-color);
}

a.selected > div > div {
  color: #fff !important;
}

.margin-bottom-2 {
  margin-bottom: 2%;
}

.cursor-pointer {
  cursor: pointer;
}

.margin-top-2 {
  margin-top: 2%;
}

.margin-top-4 {
  margin-top: 4%;
}

.custom-btn {
  background-color: #379fb4 !important;
  color: #fff !important;
}

.custom-btn:hover {
  background-color: #2d2d2d !important;
}

.pedigree-menu {
  box-shadow: 0px 2px 3px 1px rgb(235, 233, 233);
  background-color: #f7f7f7;
  padding: 6px 2px;
  border-radius: 6px;
  border: 1px solid #dedede;
}

.menu-box {
  width: 350px;
}

.menu-box .MuiCard-root {
  background-color: #dfeff5;
}

.menu-box .form-label,
.user-form .form-label {
  /* color: #000; */
  font-weight: bold;
}

.menu-box .close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.button-no-transformation {
  text-transform: none !important;
}
.m-l {
  margin-left: 1rem;
}

.no-link {
  /* color: black; */
  text-decoration: none;
}

.no-link:hover {
  text-decoration: none;
  color: var(--primary-color);
}

.margin-bottom-10 {
  margin-bottom: 10%;
}

/* .MuiOutlinedInput-input { */
/* padding: 14px 14px !important; */
/* background-color: #fff !important; */
/* } */

.MuiAutocomplete-input {
  padding: 14px 14px !important;
}

.MuiOutlinedInput-multiline {
  padding: 0 !important;
  background-color: #fff !important;
}

.MuiAutocomplete-inputRoot {
  padding: 0 !important;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.chips .chip {
  background-color: var(--primary-color);
  color: white;
  font-size: 1rem;
}

.review-background {
  /* background-color: #1c2a37; */
  padding: 20px !important;
}

.user-form .MuiOutlinedInput-adornedEnd {
  padding-right: 0;
}

.primary-color {
  color: var(--primary-color);
}

.grey-bg {
  /* background-color: #fafafa; */
  background-color: #ffffff;
}

.required:after {
  content: '*';
  color: red;
  padding-left: 5px;
}

.workflow-list {
  list-style: none;
}

.workflow-item {
  position: relative;
  font-size: 1.1rem;
  margin-block: 0.8rem;
}

.workflow-item::before {
  content: ' ';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #606060;
  left: -30px;
  border-radius: 100%;
  top: 17px;
}
.workflow-item::after {
  animation: grow 0.8s ease-out forwards;
  content: ' ';
  position: absolute;
  width: 2px;
  background-color: #606060;
  left: -26.5px;
  border-radius: 100%;
  top: 25px;
}
.workflow-item:last-child::after {
  animation: none;
  height: 0px;
}

@keyframes grow {
  0% {
    height: 0;
  }
  100% {
    height: 50px;
  }
}

.draggableItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  min-width: 250px;
  cursor: move;
  padding: 10px;
  border: solid rgb(233, 233, 233) 1px;
}

/* WORKFLOW CSS */

.tree ul {
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}
.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}
.tree li div {
  display: flex;
  border: 1px solid #ccc;
  padding: 15px 20px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 1.3rem;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.pop:hover {
  transform: scale(1.1);
}

.feedback {
  position: fixed;
  right: -69px;
  top: 50%;
  margin: 0px;
  font-weight: bolder;
  text-align: center;
  width: 160px;
  padding: 10px;
  transform: rotateZ(-90deg);
  color: white;
  z-index: 100;
}

.grecaptcha-badge {
  visibility: hidden;
}

.close-btn {
  position: absolute;
  padding: 5px;
  top: -10px;
  background-color: lightpink;
  right: -15px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  border: none;
}

#pedigree-chart {
  height: 100vh;
}
